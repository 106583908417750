import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogVideo } from 'graphql-types';
import {
  BlogIndexPageTemplate,
  PAGINATION_PAGE_SIZE
} from '../../components/Blog/blog-index-page-template';

interface BlogVideoPageProps {
  data: {
    videos: {
      edges: {
        node: SanityBlogVideo;
      }[];
      totalCount: number;
    };
  };
}

const BlogVideoPage = ({ data }: BlogVideoPageProps) => {
  const videos = data?.videos.edges.map(edge => edge.node);
  const numOfPages = Math.ceil(data?.videos.totalCount / PAGINATION_PAGE_SIZE);

  return (
    <BlogIndexPageTemplate
      pathPrefix="blog/videos"
      posts={videos}
      totalNumberOfPages={numOfPages}
      variant="video"
      showHeadliner
    />
  );
};

export const query = graphql`
  fragment BlogVideoCardInfo on SanityBlogVideo {
    _id
    categories {
      title
    }
    id
    title
    slug {
      current
    }
    image {
      _type
      ...SanityImageFragment
    }
    title
    author {
      ...BlogPostAuthor
    }
    content: _rawContent
  }

  query BlogVideoIndexQuery {
    videos: allSanityBlogVideo(
      limit: 10
      filter: {
        image: { asset: { id: { ne: null } } }
        slug: { current: { ne: null } }
      }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...BlogVideoCardInfo
        }
      }
      totalCount
    }
  }
`;

export default BlogVideoPage;
